.view-votes-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.chart-container {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
}

.chart-description-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    column-gap: 16px;
    margin-top: 20px;
    flex-wrap: wrap;
    row-gap: 16px;
}

.chart-description-container-section {
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.helper-flex-90 {
    flex-basis: 90%;
}

.chart-description-container-section-heading {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #000000;
    margin-bottom: 0;
}

.chart-description-container-section-desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
}

.votes-container {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.votes-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.votes-item-desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 4px 24px;
    color: rgba(0, 0, 0, 0.8)
}