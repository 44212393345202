@font-face {
  font-family: "Montserrat";
  src: url("/src/Assets/fonts/Montserrat/Montserrat-Regular.ttf");
}

/* formatting */

* {
  font-family: "Montserrat" !important;
}
/* * {
  font-family: 'Montserrat-Black, sans-serif' !important;
 
} */

:root {
  --main-primary: #78633a;
  --main-light-primary: #9d895f;
}

.bgprimary {
  background: var(--main-primary) !important;
}
.publish-unpublish {
  color: rgba(22, 22, 26, 0.6) !important;
}

.cancelbtn {
  position: relative;
  background: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  border-radius: 10px;
  border: 1px solid #e4e4e7;
  /* gray/800 */

  color: #27272a;
}

.cancelbtn:hover {
  background: #e6e6e6;
}

.cancelbtn:disabled {
  cursor: not-allowed;
  background: #e6e6e6;
}

.cancelbtn:disabled:hover {
  background: #e6e6e6;
}

.bold {
  font-weight: bold !important;
}

.modaltitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  /* black */

  color: #000000;
}
/* ***************** Quest area **************************/
.questlistwrapper {
  display: grid;
  grid-template-columns: 0.2fr 1fr 1fr 1fr 0.2fr;
  gap: 20px;
}
.create-quest-wrapper {
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;
}
.questheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 50px;
  /* identical to box height */

  color: #000000;
}
.questname {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;

  color: #000000;
  margin-left: 10px;
}
.searchtaskforquestwrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 16px;

  /* width: 204px; */
  height: 32px;

  background: #ffffff;
  border: 1px solid #f4f4f5;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
/* ***************** city detail area **************************/

.city-detail-grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.nft-grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.collection-image-preview {
  height: 235px;
  width: 235px;
  border: 1px solid rgba(22, 22, 26, 0.08);
  border-radius: 16px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  padding: 0px 10px;
  border-radius: 5px;
}
.checkbox-wrapper .form-control {
  border: none !important;
}
input:focus,
input.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.textarea {
  resize: none !important;
}
textarea:focus,
textarea.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
/* ***************** user card area **************************/
.family-grid-wrapper {
  width: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;
  /* height: 470px; */
  /* overflow: scroll; */
  /* overflow-x: hidden; */
}
.grid-wrapper {
  width: auto;
  display: grid;

  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;
  height: 470px;
  /* overflow: scroll; */
  /* overflow-x: hidden; */
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bec4c4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.meta-data-wrapper {
  background-color: white;
  padding: 10px;
  border: 1px solid #cecece;
  margin-top: 10px;
}

/* *****************Prize Pool area **************************/

.action {
  color: rgba(22, 22, 26, 0.6);
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.5px;
}

.prize-pool-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}
.prize-pool-btn-wrapper {
  display: flex;
  justify-content: start;
  bottom: 15px;
  position: absolute;
  /* background: white; */
  padding: 10px;
  width: 81%;
  z-index: 100;
}

.prizepoll-inactive-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 64px;
  height: 20px;
  /* Green/100 */
  background: #fff;
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height, or 145% */
  /* Green/800 */
  color: #ff0303 !important;

  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.prizepoll-active-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 64px;
  height: 20px;
  /* Green/100 */
  background: #fff;
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height, or 145% */
  /* Green/800 */
  color: #40a866 !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.border-radius {
  border-radius: 30px;
}

.activeborder {
  border: 6px solid #656363;
  border-radius: 10px;
}

.searchbox {
  padding: 7px 0px 7px 0px !important;
  width: 100%;
}
.searchbox-inputfeild {
  margin-top: -28px !important;
  padding-left: 40px !important;
}
.rank {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 2px 8px; */
  gap: 8px;

  width: 76px;
  height: 17px;

  border: 1px solid #cecece;
  border-radius: 4px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;

  color: #f87171;
}

.block-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}

.block-user-heading {
  font-size: 14px;
  margin-bottom: 0;
}

.task {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 8px;

  width: 113px;
  height: 17px;

  border: 1px solid #cecece;
  border-radius: 4px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;

  color: #ffd233;
}
.teamsection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 8px;

  /* width: 249px; */
  height: 17px;

  border: 1px solid #cecece;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;

  color: #4763e4;
}
.score {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 8px;

  width: 92px;
  height: 17px;

  border: 1px solid #cecece;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;

  /* green */

  color: #16a34a;
}

.userTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #000000;
}

.userStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;

  width: 64px;
  height: 20px;

  /* Green/100 */

  background: #def7ec;
  border-radius: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height, or 145% */

  /* Green/800 */

  color: #03543f !important;
}

.userBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;

  width: 64px;
  height: 20px;

  /* Green/100 */

  background: rgba(255, 0, 0, 0.3);
  border-radius: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}

.location {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  margin-left: 10px;
  /* identical to box height */

  /* main 1 */

  color: #78633a;
}

.listvalue {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;

  color: rgba(22, 22, 26, 0.6);
}
.listheaing {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;

  color: #16161a;
}

.family-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #000000;
}
.duration-passed-title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #9fa6b2;
}

.duration-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 12px;
  gap: 7px;

  width: 143px;
  height: 26px;

  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.duration-title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* green */

  color: #16a34a;
}
.duration-title-ago {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* green */

  color: #9fa6b2;
}

/* ***************** headig area **************************/
.login-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding-top: 8px;

  /* black */

  color: #000000;
}

.ligh-primary {
  color: var(--main-light-primary);
}

label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */
  padding-bottom: 8px;
  /* black */

  color: #000000;
}
/* ************ announcement area ************************/

.add-announcement-modal-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.add-votes-modal-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}
.votes-heading {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.votes-add-btn {
  width: 133px;
  height: 48px;
}
.votes-eye-icon {
  height: 22px;
}
.border {
  border: none !important;
}

.savecancelbtnhightwidth {
  height: 48px !important;
  width: 200px !important;
}

.savecancelbtnlowtwidth {
  height: 32px !important;
  width: 100px !important;
}

.votes-progress .ant-progress-bg {
  height: 15px !important;
}

.ant-progress-text {
  color: #4e4e4e !important;
}

/* ************ task hint area ************************/

.task-grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.text-detail {
  color: rgba(22, 22, 26, 0.6);
}
.gap15 {
  gap: 15px;
}
.gap5 {
  gap: 5px;
}
/* ************ pagination area ************************/

.ant-pagination .ant-pagination-item-active {
  color: white !important;
  /* font-weight: 600; */
  /* color: #ffffff; */
  border-color: #78633a;
  background: #78633a;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  /* identical to box height, or 143% */

  /* white */

  color: #ffffff;
}
.ant-pagination .ant-pagination-item a {
  border: 1px solid #78633a;
  border-radius: 3px;
  color: black !important;
  /* color: #FFFFFF !important; */
}
/* ************ Card  Date area ************************/

.total-time-footer {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 80%;
  bottom: 10px;
  position: fixed;
  background-color: white;
  height: 40px;
  padding-right: 20px;
  border-radius: 5px;
}
.total-time-heading {
  color: var(--red, #dc2626);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.total-time {
  color: var(--red, #dc2626);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.taskavatarimage {
  height: 40px;
  width: 40px;
  object-fit: contain;
}
.typeofclueactive {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  font-feature-settings: "ss01" on, "cv01" on, "cv11" on;
  /* Black/40% */
  color: green;
  font-feature-settings: "ss01" on, "cv01" on, "cv11" on;

  /* Black/40% */

  flex-direction: row;
  justify-content: center;
  padding: 1px 4px;
  height: 22px;
  /* Primary/Purple */
  background: #dcfce7;
  border-radius: 4px;
}
.typeofinactive {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  font-feature-settings: "ss01" on, "cv01" on, "cv11" on;
  /* Black/40% */
  color: red;
  font-feature-settings: "ss01" on, "cv01" on, "cv11" on;

  /* Black/40% */

  flex-direction: row;
  justify-content: center;
  padding: 1px 4px;
  height: 22px;
  /* Primary/Purple */
  background: #f8b2ad;
  border-radius: 4px;
}
.typeofblocked {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  font-feature-settings: "ss01" on, "cv01" on, "cv11" on;
  /* Black/40% */
  color: rgb(133, 95, 0);
  font-feature-settings: "ss01" on, "cv01" on, "cv11" on;

  /* Black/40% */

  flex-direction: row;
  justify-content: center;
  padding: 1px 4px;
  height: 22px;
  /* Primary/Purple */
  background: #f8e2ad;
  border-radius: 4px;
}
a {
  color: #78633a !important;
}
.ant-menu-item-selected a {
  color: white !important;
}
.carddate {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  font-feature-settings: "ss01" on, "cv01" on, "cv11" on;
  /* Black/40% */
  color: rgba(0, 0, 0, 0.4);
  font-feature-settings: "ss01" on, "cv01" on, "cv11" on;

  /* Black/40% */

  flex-direction: row;
  justify-content: center;
  padding: 1px 4px;
  width: 95px;
  height: 22px;
  /* Primary/Purple */
  background: #e5ecf6;
  border-radius: 4px;
}
.quest-wrapper {
  height: 48vh;
  overflow: auto;
}
.cardheading {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #000000;
}
.carddescription {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* or 16px */

  color: #000000;
}
/* ************ sidenav area ************************/

.ant-menu .ant-menu-item-selected {
  background: #78633a;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #ffffff !important;
}

.ant-menu .ant-menu-title-content {
  margin-inline-start: 10px;
}
.ant-menu .ant-menu-item-selected {
  color: #ffffff !important;
}
a {
  text-decoration: unset !important;
  /* color: #BAA068 !important ; */
  color: #78633a;
}
.ant-menu .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #78633a;
  /* color:#78633A; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.ant-menu {
  color: #78633a !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* color:#78633A */
}
/* ************ margin area ************************/

.mt40 {
  margin-top: 40px !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt25 {
  margin-top: 25px !important;
}
.mt16 {
  margin-top: 16px !important;
}
.mt8 {
  margin-top: 8px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt-32 {
  margin-top: 32px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt90 {
  margin-top: 90px !important;
}
.ml30 {
  margin-left: 30px;
}
.ml10 {
  margin-left: 10px;
}
/*.ml18 {*/
/*  margin-left: 18%;*/
/*}*/
.ml9 {
  margin-left: 9px;
}
.ml17 {
  margin-left: 17px;
}
.mr25 {
  margin-right: 25px;
}
.ml12 {
  margin-left: 12px;
}
.mr70 {
  margin-right: 70px;
}

.loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/******************** Loader user data ********************* */
.loader-user {
  height: 310px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
/******************** cursor area ********************* */

.cursor {
  cursor: pointer;
}

.cursor-nodrop {
  cursor: no-drop;
}

/* &&&&&&&&&&&&&&&&&& error ****************** */
.Requirederror .ant-select-selector,
.Requirederror .css-1s2u09g-control,
.react-tel-input.Requirederror .form-control,
.errorFeild {
  border: 1px solid red !important;
}

.ReqFeild {
  display: inline-block !important;
  height: 0 !important;
  border: 0 !important;
  z-index: -1 !important;
  position: absolute !important;
  outline: none !important;
  opacity: 0;
}

/* ************** Button area ******************* */
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Upload Asset";
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 21px 12px;
  color: white;
  position: static;
  max-width: 200px;
  height: 48px;
  left: 0px;
  top: 32px;
  font-style: normal;
  font-weight: 800;
  font-size: 90%;
  line-height: 24px;
  background: #78633a;
  border-radius: 8px;
  cursor: pointer;
  font-family: "futura-Bold" !important;
  justify-content: center;
}
.center {
  text-align: center;
}
.logout-btn-wrapper {
  display: flex;
  justify-content: end;
  width: 100%;
  padding-right: 18px;
}
.logout-btn {
  background-color: var(--main-primary);
  font-family: "League Spartan";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 72% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: #ffffff;

  border: 1px solid #fcfcfc;
  border-radius: 5px;
  padding: 10px 50px;
  cursor: pointer;
}
.sidebar-logo-wrapper {
  position: fixed;
  z-index: 1;
}
.sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95%;
}
.savebtn {
  background-color: var(--main-primary);

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;

  padding: 10px 30px;
  font-style: normal;

  letter-spacing: -0.03em;
  border: 0;
  /* width: 100%; */
  border-radius: 12px;
}

.parimarybtn {
  background-color: var(--main-primary);

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;

  padding: 10px 100px;
  font-style: normal;

  letter-spacing: -0.03em;
  border: 0;
  width: 100%;
  border-radius: 12px;
}
.parimarybtn-submit {
  background-color: var(--main-primary);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;

  padding: 5px 50px;
  font-style: normal;

  letter-spacing: -0.03em;
  border: 0;
  width: 100%;
  border-radius: 12px;
}

.addbtn {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px;
  gap: 8px;
  /* 
width: 236px;
height: 48px; */

  /* main 2 */
  cursor: pointer;
  background: #baa068;
  border-radius: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  /* white */

  color: #ffffff;
}

.addbtn:hover {
  background: #9b8549;
}

.addbtn:disabled {
  cursor: not-allowed;
  background: #8a8a8a;
  color: #baa068;
}

.addbtn:disabled:hover {
  background: #8a8a8a;
}
/*************** height area *************************/

.ant-table-wrapper .ant-table-thead > tr > td {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  color: rgba(100, 116, 139, 0.7) !important;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  color: rgba(100, 116, 139, 0.7) !important;
}

.time {
  color: #dc2626;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.table-column-body-data {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* Secondary */

  color: #000;
}
.table-column-sr-no {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Dark */

  color: #1e293b;
}

.sidebar-bottom-profile {
  padding-top: 30px;
  text-align: center;
  width: 100%;
}

.side-bar-email {
  color: var(--main-1, #78633a);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.side-bar-role {
  color: var(--main-1, #78633a);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/*************** height area *************************/

.h100vh {
  height: 100vh;
}
.h32 {
  height: 32px;
}
.h134 {
  height: 134px !important;
}
/*************** width area *************************/

.w450 {
  width: 450px !important;
}
.w80 {
  width: 80% !important;
}

.w96 {
  width: 96% !important;
}
.w30 {
  width: 30% !important;
}
.w20 {
  width: 20% !important;
}
.w97 {
  width: 97% !important;
}

/*************** padding area *************************/

.pb40 {
  padding-bottom: 40px;
}

.p10zero {
  padding: 10px 0px;
}
.p1030 {
  padding: 10px 30px;
}
.p1040 {
  padding: 10px 40px;
}
.p1060 {
  padding: 10px 60px;
}
.pr0 {
  padding-right: 0px !important;
}
.pr10 {
  padding-right: 10px !important;
}
.pl0 {
  padding-left: 0px !important;
}
.pl10 {
  padding-left: 10px !important;
}
.p10 {
  padding: 10px !important;
}
.textarea-option {
  height: 50px !important;
  padding: 10px !important;
}
/*************** padding area *************************/

.test {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #000000;
}
/*************** font area *************************/
.fs16 {
  font-size: 16px;
}

.REM2 {
  font-size: calc(1rem + 1.2vw);
  line-height: 1.5;
  letter-spacing: -1.28px;
}

.newpassheading {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  /* black */

  color: #000000;
}
/* ************ Input area ************************/
.errorBorder {
  border-color: red !important;
}
.errorText {
  color: red !important;
}
.form-control {
  border: 1px solid #9d895f;
  border-radius: 12px;
}
.form-control::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* gray/400 */

  color: #9fa6b2;
}

.input-feild-border {
  border: 1px solid #9d895f !important;
  border-radius: 12px !important;
}

.was-validated .form-control:valid {
  border: 1px solid #9d895f !important;
  border-color: none !important;
}
.eyeicon {
  float: right;
  margin-top: -27px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  margin-right: 12px;
  color: #4b5563;
}
.hidden-input {
  height: 0px;
  width: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  text-align: center;
  /* border: none; */
  opacity: 0;
}

/* * *****************Color Area ****************** */
.sub-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  /* black */

  color: #000000;
  /* cursor: pointer; */
}
.nft-sub-text {
  color: rgba(22, 22, 26, 0.6);

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin-top: 15px;
}

/* * *****************Media Query ****************** */
@media only screen and (max-width: 600px) {
  label {
    line-height: unset;
  }
}

/**************************** loader area ******************* */

.spinner {
  width: 100% !important;
  text-align: center;
}

.whitespinner > div {
  width: 18px;
  height: 18px;
  background-color: #fff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.whitespinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.whitespinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* antd spinner color */
.ant-spin-dot-item {
  background-color: rgba(120, 99, 58, 1) !important;
}

.read-more {
  color: rgba(120, 99, 58, 1);
  display: flex;
  justify-content: end;
  justify-self: end;
  cursor: pointer;
}

/* sidebar Header */
.Header-Sidebar {
  padding: 0;
  height: "62px";
  position: "sticky";
  top: 0;
  z-index: 1;
  width: "100%";
  display: "flex";
  align-items: "center";
  border-bottom: "1px solid #CECECE";
}
/* User List titles */

.flex-row-list {
  display: flex;
  flex-direction: row;
}

.flex-row-gap-list {
  display: flex;
  flex-direction: row;
  gap: 3;
}

/* toogle view */
.radio-button-selected {
  background-color: red;
  color: white;
}

.radio-button-unselected {
  background-color: transparent;
  color: black;
}

/* Boast styles */

.Boast-Loading {
  display: "flex";
  width: "100px";
  height: "100px";
  justify-content: "center";
  text-align: "center";
  align-items: "center";
  border: "1px solid #16161A1A";
  border-radius: "16px";
}

/* Task fields allignment */

.Task-Input-Column {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 60%;
}

.Centered-Con {
  display: flex;
  text-align: center;
  margin-top: 13px;
}

.exchange-container {
  display: flex;
  align-items: left;
  height: 80vh;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.backButton:hover {
  background: rgb(229, 229, 229);
}

.companyHeader {
  position: absolute;
  top: -35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.relative {
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.boastStyle {
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  text-align: center;
  align-items: center;
  border: 1px solid #16161a1a;
  border-radius: 16px;
}

.boastImageLink {
  width: 100px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.plusButton {
  background-color: black;
  color: white;
  margin-top: 0px;
}

.editButton {
  background-color: transparent;
  color: black;
  margin-top: 11px;
}

.formContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.formHeader {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 20px 0;
}

.imageContainer {
  width: 100px;
}

.image {
  width: 100px;
  height: 100px;
}

.upload {
  margin-top: 21px;
}

.formFields {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.formFooter {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.spin {
  position: absolute;
  top: 10px;
}

.poolContainer {
  display: flex;
  gap: 40px;
}

.fieldContainer {
  display: flex;
  gap: 20px;
}

.poolMap {
  margin-bottom: 200px;
  margin-left: 30px;
}

.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  align-items: center;
}

.modal-container {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.error-message {
  color: red;
}

.map-container {
  height: 500px;
}

.button-container {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  padding-top: 30px;
}
